

































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { MODULES } from './constants';

@Component({
    components: {
        AppBar: () => import('@/components/AppBar.vue'),
        Landing: () => import('@/components/Landing.vue'),
        AuthWrapper: () => import('@/modules/auth/components/AuthWrapper.vue'),
    },
})
export default class App extends Vue {
    get areWeOnAuthViews(): boolean {
        return (
            this.$route.name === MODULES.AUTH.ROUTE.NAME.AUTH_CALLBACK ||
            this.$route.name === MODULES.AUTH.ROUTE.NAME.LOGOUT
        );
    }
}
