import { App } from './app.models';
import activeModuleList from './active-module-list.json';

const coreModules: string[] = ['auth'];
const modules: string[] = activeModuleList;

const envDependingValues =
    process.env.NODE_ENV === 'development'
        ? {
              clientId: '7g1sq2jmrlpg53g8jejoji84pa',
              redirectUri: 'https://localhost:3000/auth_callback',
              logoutUri: 'https://localhost:3000/logout',
              baseUrl: 'https://apiparadise.auth.us-east-1.amazoncognito.com',
              cognitoIssuer:
                  'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_GE22NOLee',
          }
        : {
              clientId: '7g1sq2jmrlpg53g8jejoji84pa',
              redirectUri: 'https://www.apiparadise.com/auth_callback',
              logoutUri: 'https://www.apiparadise.com/logout',
              baseUrl: 'https://apiparadise.auth.us-east-1.amazoncognito.com',
              cognitoIssuer:
                  'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_GE22NOLee',
          };

export default {
    routing: {
        base: process.env.BASE_URL,
        mode: 'history',
    },
    modules: coreModules.concat(modules),
    auth: {
        clientId: envDependingValues.clientId,
        redirectUri: envDependingValues.redirectUri,
        logoutUri: envDependingValues.logoutUri,
        cognitoIssuer: envDependingValues.cognitoIssuer,
        baseUrl: envDependingValues.baseUrl,
        scope: 'email openid',
        loginEndpoint: '/login',
        logoutEndpoint: '/logout',
        tokenEndpoint: '/oauth2/token',
    },
    storage: window.localStorage,
} as App.Config;
