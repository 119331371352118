import Vue from 'vue';
import Vuex, { ModuleTree, Store } from 'vuex';
import vuexPersistPlugin from '@/plugins/vuex-persist';

Vue.use(Vuex);

export default (modules: ModuleTree<unknown>): Store<unknown> => {
    return new Vuex.Store({
        modules,
        plugins: [vuexPersistPlugin.plugin],
    });
};
