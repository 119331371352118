export const MODULES = {
    AUTH: {
        ROUTE: {
            NAME: {
                AUTH_CALLBACK: 'auth-callback',
                LOGOUT: 'logout',
            },
        },
        STORE: {
            NAMESPACE: {
                AUTH: 'auth',
            },
            GETTER: {
                USER_TOKENS: `USER_TOKENS`,
                TOKEN_VERIFICATION_STATUS: `TOKEN_VERIFICATION_STATUS`,
                LOGIN_URL: `LOGIN_URL`,
                LOGOUT_URL: `LOGOUT_URL`,
                USER_CLAIM_ID: `USER_CLAIM_ID`,
            },
            MUTATION: {
                SET_USER_TOKENS: `SET_USER_TOKENS`,
                SET_TOKEN_VERIFICATION_STATUS: `SET_TOKEN_VERIFICATION_STATUS`,
                CLEAN_USER_TOKENS: `CLEAN_USER_TOKENS`,
                SET_USER_CLAIM_ID: `SET_USER_CLAIM_ID`,
                SET_LOGIN_URL: `SET_LOGIN_URL`,
                SET_LOGOUT_URL: `SET_LOGOUT_URL`,
            },
        },
        STORAGE: {
            STORAGE_KEY_USER_TOKENS: 'user-tokens',
        },
        TOKEN_VERIFICATION_STATUS: {
            TOKEN_UNCHECKED: 'Token not yet checked',
            TOKEN_VERIFIED: 'Token verified',
            TOKEN_EXPIRED: 'Token expired',
            INVALID_TOKEN: 'The token is invalid',
            UNKNOWN_KID: 'Claim made for unknown KID',
            CLAIM_EXPIRED_OR_INVALID: 'Claim is expired or invalid',
            CLAIM_ISSUER_IS_INVALID: 'Claim issuer is invalid',
            CLAIM_USE_IS_NOT_FOR_ID: 'Claim use is not for ID',
        },
    },
    HOMEPAGE: {
        ROUTE: {
            NAME: {
                HOME: 'home',
            },
        },
    },
    URL_CACHE_CHECKER: {
        ROUTE: {
            NAME: {
                URL_CACHE: 'url-cache',
            },
        },
        STORE: {
            NAMESPACE: {
                CACHED_URL_COLLECTION: 'cachedUrlCollection',
                CACHED_URL_REQUEST_QUEUE: 'cachedUrlRequestQueue',
                UPLOADED_FILE: 'uploadedFile',
            },
            GETTER: {
                CACHE_URL_STATUS_COLLECTION: 'CACHE_URL_STATUS_COLLECTION',

                CACHE_URL_STATUS_PROGRESS: 'CACHE_URL_STATUS_PROGRESS',
                CACHE_URL_STATUS_REQUEST_QUEUE:
                    'CACHE_URL_STATUS_REQUEST_QUEUE',

                SELECTED_FILE: 'SELECTED_FILE',
                URL_LIST: 'URL_LIST',
                FILE_IS_READY: 'FILE_IS_READY',
            },
            MUTATION: {
                PUSH_CACHE_URL_STATUS_COLLECTION: `PUSH_CACHE_URL_STATUS_COLLECTION`,
                CLEAN_CACHE_URL_STATUS_COLLECTION: `CLEAN_CACHE_URL_STATUS_COLLECTION`,

                SET_FETCHING_CACHE_URL_STATUS_PROGRESS: `SET_FETCHING_CACHE_URL_STATUS_PROGRESS`,
                SET_CACHE_URL_STATUS_REQUEST_QUEUE: `SET_CACHE_URL_STATUS_REQUEST_QUEUE`,
                CLEAN_URL_CACHE_STATUS_REQUEST_QUEUE: `CLEAN_URL_CACHE_STATUS_REQUEST_QUEUE`,
                CLEAN_URL_GROUP_FROM_REQUEST_QUEUE: `CLEAN_URL_GROUP_FROM_REQUEST_QUEUE`,

                SET_FILE_IS_READY: 'SET_FILE_IS_READY',
                SET_URL_LIST: 'SET_URL_LIST',
            },
            ACTION: {
                SET_SELECTED_FILE: 'SET_SELECTED_FILE',
            },
        },
    },
};
