import Vue from 'vue';
import AppComponent from '@/App.vue';
import '@/registerServiceWorker';
import vuetify from '@/plugins/vuetify';
import installModules from '@/modules/install-modules';
import { App } from '@/app.models';
import config from '@/config';
import getRouter from '@/router';
import getStore from '@/store';
import dynamicImportWrapper from '@/lib/helpers/dynamic-import-wrapper';

Vue.config.productionTip = false;

installModules(dynamicImportWrapper, config.modules)
    .then((moduleConfig: App.ModuleConfig) => {
        new Vue({
            router: getRouter(moduleConfig.routes, config.routing),
            store: getStore(moduleConfig.storeModules || {}),
            vuetify,
            render: (h) => h(AppComponent),
        }).$mount('#app');
    })
    .catch((error: Error) => {
        throw new Error(
            `An error happened on the install modules proccess -> ${error}`
        );
    });
