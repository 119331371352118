import { RouteConfig } from 'vue-router';
import { EsModuleComponent } from 'vue/types/options';
import { ModuleTree } from 'vuex';
import { App } from './../app.models';

/**
 * Installs the application modules. All modules must have a install.ts file
 * and each module should define its own routes, its own stores, and its own
 * store middlewares.
 *
 * Each module should work as independent as possible, to minify strong
 * dependencies between them. If two modules depend too much on each other
 * thats a clear sign for merging both into just one module.
 *
 * @param modulesToInstall An array of strings referring to the module
 *  folder names we want to register in the app.
 */
export default async (
    dynamicImportWrapper: App.DynamicImportWrapper,
    modulesToInstall: string[] = []
): Promise<App.ModuleConfig> => {
    const routes: RouteConfig[] = [];
    let storeModules: ModuleTree<unknown> = {};

    for (const moduleName of modulesToInstall) {
        let moduleConfig;

        try {
            const nodeModule: EsModuleComponent = await dynamicImportWrapper.loader(
                moduleName
            );
            moduleConfig = nodeModule.default as App.ModuleConfig;
        } catch (error) {
            return Promise.reject(
                new Error(`Error while installing modules: "${error}"`)
            );
        }

        routes.push(...moduleConfig.routes);
        storeModules = { ...storeModules, ...moduleConfig.storeModules };
    }

    return Promise.resolve({ routes, storeModules });
};
