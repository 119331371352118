import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colorScheme = {
    primary: '#ff0b9a',
    secondary: '#020920',
    accent: '#8EA4C3',
    error: '#b92d2d',
    warning: '#ec9412',
    info: '#989898',
    success: '#255227',
    anchor: '#ff0b9a',
};

export default new Vuetify({
    theme: {
        themes: {
            light: colorScheme,
            dark: colorScheme,
        },
    },
});
