import { App } from '@/app.models';
import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

Vue.use(Router);

export default (routes: RouteConfig[], config: App.RoutingConfig): Router =>
    new Router({
        mode: config.mode,
        base: config.base,
        routes,
    });
